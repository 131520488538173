import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, InputGroup, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Select from "react-select";
import NumberPicker from "react-widgets/NumberPicker";
import "react-widgets/styles.css";
import housr_logo_small from "../Icons/housr_icon.svg";
import cities from "../json/gb.json";
import { PageCard } from "./PageCard";
import "./css/LeadTenant.css";
import { Faqs } from "./Faqs";

export const LeadTenant = () => {
    let { state } = useLocation();
    const user = state?.user;

    const [searchParams] = useSearchParams();

    const [country, setCountry] = useState("GB");
    const [number, setNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [city, setCity] = useState("");
    const [source, setSource] = useState(user ? user.source : "");
    const [sourceOther, setSourceOther] = useState("");
    const [email, setEmail] = useState("");
    const [tenants, setTenants] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeading, setAlertHeading] = useState("");
    const [alertBody, setAlertBody] = useState("");
    const [error, setError] = useState("");
    const [tenantsModal, setTenantsModal] = useState(false);
    const [flatmateData, setFlatmateData] = useState([]);
    const [addTenantsIndex, setAddTenantsIndex] = useState([]);
    const [tenantsAlert, setTenantsAlert] = useState({});
    const [showTenantsAlert, setShowTenantsAlert] = useState(false);
    const [applyDiscount, setApplyDiscount] = useState(false);
    const [discount, setDiscount] = useState(searchParams.get("discount"));

    const referral = searchParams.get("referral");

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setUser();
        }
        cities.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            return 1;
        });

        if (tenants > 2 && flatmateData.length !== 2) {
            setApplyDiscount(false);
            setDiscount(false);
        }
    }, [tenants]);

    const setUser = () => {
        setFirstName(user?.name?.split(" ")[0]);
        setLastName(user?.name?.split(" ")[1]);
        setCity(user.city);
        setEmail(user.email);
        setTenants(user.bedrooms);
        setNumber(user.contact_no);
    };

    const handleFlatmateChange = (value, field, tenantIndex) => {
        setFlatmateData((currentFlatmateData) => {
            const newData = [...currentFlatmateData];
            newData[tenantIndex] = {
                ...currentFlatmateData[tenantIndex],
                [field]: value,
            };
            return newData;
        });
    };

    const submitForm = () => {
        if (firstName === "" || lastName === "") {
            setAlertHeading("Error with your name");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your full name has not been completely filled in yet!"
            );
            setShowAlert(true);
        } else if (city === "") {
            setAlertHeading("Error with your city");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your city has not been chosen!"
            );
            setShowAlert(true);
        } else if (email === "") {
            setAlertHeading("Error with your email");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your email has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (number === "") {
            setAlertHeading("Error with your number");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your number has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (
            country === "GB" &&
            (number.length !== 13 || number[3] != 7)
        ) {
            setAlertHeading("Error with your phone number");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your phone number is not valid!"
            );
            setShowAlert(true);
        } else if (!tenants) {
            setAlertHeading("Error with your number of tenants");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your number of tenants has not been filled in!"
            );
            setShowAlert(true);
        } else {
            setError("");

            getQuote();
        }
    };

    const saveTenantsDetails = () => {
        flatmateData.map((flatmate) => {
            if (
                flatmate.firstName === "" ||
                flatmate.lastName === "" ||
                !flatmate.firstName ||
                !flatmate.lastName
            ) {
                setTenantsAlert({
                    heading: "Error with one or more of your tenant's name",
                    body: "Please ensure that you have filled in all of the sections for your tenants. We have detected one or more of your tenants' full name has not been completely filled in yet!",
                });
                setShowTenantsAlert(true);
            } else if (flatmate.number === "" || !flatmate.number) {
                setTenantsAlert({
                    heading:
                        "Error with one or more of your tenant's phone number",
                    body: "Please ensure that you have filled in all of the sections for your tenants. We have detected one or more of your tenants' phone number has not been completely filled in yet!",
                });
                setShowTenantsAlert(true);
            } else if (
                country === "GB" &&
                (flatmate.number.length !== 13 || flatmate.number[3] != 7)
            ) {
                setTenantsAlert({
                    heading:
                        "Error with one or more of your tenant's phone number",
                    body: "Please ensure that you have filled in all of the sections for your tenants. We have detected one or more of your tenants' phone number is not valid!",
                });
                setShowTenantsAlert(true);
            } else {
                setTenantsModal(false);
                if (tenants === 2 && flatmateData.length === 1) {
                    setApplyDiscount(true);
                    setDiscount("GET10OFF");
                } else if (tenants > 2 && flatmateData.length === 2) {
                    setApplyDiscount(true);
                    setDiscount("GET10OFF");
                }
            }
        });
    };

    const getQuote = () => {
        let url = `/signup/bill-quotes/${tenants}`;

        if (referral) {
            url += `?referral=${referral}`;
        }

        if (user) {
            axios
                .post("/leadTenant/update", {
                    number: number,
                    name: firstName + " " + lastName,
                    city: city,
                    email: email,
                    tenants: tenants,
                    uuid: user.uuid,
                })
                .then((res) => {
                    if (res.status === 200) {
                        navigate(url, {
                            state: {
                                leadTenantId: user.id,
                                leadTenantNumber: number,
                                leadTenantName: firstName + " " + lastName,
                                city,
                            },
                        });
                    }
                })
                .catch((e) => {
                    console.error("Error: ", e);
                    setAlertHeading("Error with your phone number");
                    setAlertBody(e.response?.data);
                    setShowAlert(true);
                });
        } else {
            let trueSource = source === "Other" ? sourceOther : source;

            let url = `/signup/bill-quotes/${tenants}`;

            if (referral) {
                url += `?referral=${referral}`;
            }

            axios
                .post("/leadTenant", {
                    number: number,
                    name: firstName + " " + lastName,
                    city: city,
                    email: email,
                    tenants: tenants,
                    source: trueSource,
                    discount,
                    flatmateData: applyDiscount ? flatmateData : null,
                })
                .then((res) => {
                    window.dataLayer.push({
                        event: "bills quote received",
                        eventProps: {
                            category: "bills",
                            action: "quote received",
                            label: "bills quote received",
                            value: firstName + " " + lastName,
                        },
                    });
                    if (res.status === 200) {
                        const flatmateDataUpdated = [...flatmateData];

                        if (res.data?.additionalTenantIdOne) {
                            flatmateDataUpdated[0] = {
                                ...flatmateDataUpdated[0],
                                id: res.data?.additionalTenantIdOne,
                            };
                        }

                        if (res.data?.additionalTenantIdTwo) {
                            flatmateDataUpdated[1] = {
                                ...flatmateDataUpdated[1],
                                id: res.data?.additionalTenantIdTwo,
                            };
                        }

                        navigate(url, {
                            state: {
                                leadTenantId: res.data?.lastInsertedId,
                                leadTenantNumber: number,
                                leadTenantName: firstName + " " + lastName,
                                leadTenantEmail: email,
                                discount: discount,
                                flatmateData: flatmateDataUpdated,
                                hubSpotContactIds: res.data?.hubSpotContactIds,
                                city,
                            },
                        });
                    }
                })
                .catch((e) => {
                    console.error("Error: ", e);
                    setAlertHeading("Error");
                    setAlertBody(e.response?.data);
                    setShowAlert(true);
                });
        }
    };

    const AlertValidation = () => {
        return (
            <Alert
                show={showAlert}
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                className="error-alert"
            >
                <Alert.Heading>{alertHeading}</Alert.Heading>
                <p>{alertBody}</p>
            </Alert>
        );
    };

    return (
        <div className="bg-white h-100 w-100 pt-5 justify-content-center d-flex" style={{paddingBottom: "20vh"}}>
            <Form className="lead-tenant-signup-form my-auto">
                <div className="housr-logo">
                    <img src={housr_logo_small} alt="logo" />
                </div>
                <h1 class="d-flex justify-content-start lead-tenant-signup-header my-3">
                    Get a Bills Bundle Quote{" "}
                </h1>
                <AlertValidation />
                <Form.Group>
                    <p className="input-title">Full Name</p>
                    <InputGroup hasValidation>
                        <Form.Control
                            aria-label="First name"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) =>
                                setFirstName(
                                    e.target.value.charAt(0).toUpperCase() +
                                        e.target.value.slice(1)
                                )
                            }
                        />
                        <Form.Control
                            aria-label="Last name"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) =>
                                setLastName(
                                    e.target.value.charAt(0).toUpperCase() +
                                        e.target.value.slice(1)
                                )
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter your full name
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <p className="input-title">Email Address</p>
                    <InputGroup>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <p className="input-title">Choose City</p>
                    <Select
                        placeholder=""
                        options={cities}
                        value={{ value: city, label: city }}
                        onChange={(option) => setCity(option.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <p className="input-title">Number of Tenants</p>
                    <div>
                        <NumberPicker
                            value={tenants}
                            max={12}
                            min={1}
                            onChange={(num) => {
                                if (num === 2) {
                                    setAddTenantsIndex([0]);
                                } else if (num > 2) {
                                    setAddTenantsIndex([0, 1]);
                                }
                                setTenants(num);
                            }}
                        />
                    </div>
                </Form.Group>
                <Form.Group>
                    <p className="input-title">Phone Number</p>
                    <div>
                        <PhoneInput
                            placeholder="Enter phone number"
                            withCountryCallingCode
                            defaultCountry={country}
                            onCountryChange={setCountry}
                            value={number}
                            onChange={(num) => {
                                setNumber(num);
                            }}
                        />
                    </div>
                    <p>Your phone number is required to complete the process</p>
                </Form.Group>
                <Form.Group>
                    <p className="input-title">How did you hear about us?</p>
                    <Select
                        placeholder=""
                        value={source ? { value: source, label: source } : ""}
                        options={[
                            { value: "Invades", label: "Invades" },
                            { value: "Instagram", label: "Instagram" },
                            { value: "Facebook", label: "Facebook" },
                            { value: "Word of mouth", label: "Word of mouth" },
                            { value: "Flyer/Event", label: "Flyer/Event" },
                            { value: "Google", label: "Google" },
                            { value: "Other", label: "Other" },
                        ]}
                        onChange={(option) => setSource(option.value)}
                    />
                    {source === "Other" && (
                        <InputGroup>
                            <Form.Control
                                placeholder="Please specify where you heard about us"
                                className="mt-2"
                                type="text"
                                onChange={(e) => setSourceOther(e.target.value)}
                            />
                        </InputGroup>
                    )}
                </Form.Group>
                <div className="mb-lg-2 mb-1">
                    <Button
                        className="get-quote-button w-100"
                        onClick={() => submitForm()}
                    >
                        Get Quote
                    </Button>
                </div>
                <p style={{ fontSize: 12 }}>
                    By completing this form you consent to being contacted by
                    the Housr Bills team regarding your quote.
                </p>
            </Form>
            {error && (
                <div
                    className="mt-3 bg-danger py-3"
                    style={{ color: "white", height: "10%", width: "50%" }}
                >
                    <h5>Error</h5>
                    <span>{error}</span>
                </div>
            )}
        </div>
    );
};
